import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (data, key) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "col-fixed",
      key: key
    }, [
      _createVNode(_component_item, {
        data: data,
        editKey: key
      }, null, 8, ["data", "editKey"])
    ]))
  }), 128))
}