import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ea6c00c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "item__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Knob = _resolveComponent("Knob")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: "item",
    "data-key": _ctx.editKey
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["item__header", { 'item__header--active' : _ctx.data?.id > 0 }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.view && _ctx.view(...args)))
      }, [
        _createElementVNode("img", {
          alt: "Poster",
          src: _ctx.data.posterUrl
        }, null, 8, _hoisted_1)
      ], 2)
    ]),
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.data.name), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.data.myRating)
          ? (_openBlock(), _createBlock(_component_ProgressBar, {
              key: 0,
              class: "item__rating",
              value: _ctx.data.myRating,
              showValue: true,
              title: "My Rating"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.data.myRating && _ctx.data.globalRating)
          ? (_openBlock(), _createBlock(_component_ProgressBar, {
              key: 1,
              class: "item__rating",
              value: _ctx.data.globalRating,
              showValue: true,
              title: "Global Rating"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_Knob, {
              key: 2,
              modelValue: _ctx.rating,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rating) = $event)),
              size: 150,
              class: "item_rating"
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (!_ctx.data.year)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 3,
              class: "item__edit",
              label: _ctx.isEditing ? 'Save' : 'Edit',
              onClick: _ctx.edit
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 4,
              class: "item__delete",
              label: 'Delete',
              onClick: _ctx.remove
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_ToggleButton, {
              key: 5,
              class: "item__flagged",
              modelValue: _ctx.flagged,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flagged) = $event)),
              onLabel: "Flagged",
              offLabel: "Unflagged"
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_Calendar, {
              key: 6,
              id: "date",
              modelValue: _ctx.selectedDate,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedDate) = $event)),
              showIcon: false,
              showTime: false,
              selectionMode: "single",
              maxDate: new Date()
            }, null, 8, ["modelValue", "maxDate"]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.data.year)), 1)
    ]),
    _: 1
  }, 8, ["data-key"]))
}