
    import { defineComponent, onMounted, ref } from 'vue'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    import Image from 'primevue/image'
    import Card from 'primevue/card'
    import MovieView from '@/models/movie-view'
    import { useRoute } from 'vue-router'
    import Divider from 'primevue/divider'

    export default defineComponent({
        name: 'Movie',
        components: {
            Image,
            Card,
            Divider,
        },
        setup() {
            const movieView = ref<MovieView>()
            // Look up the movie information
            const store = useStore(storeKey)
            const route = useRoute()

            // TODO: Lionking 2019 and planet earth has wrong movie ID

            onMounted(async () => {
                await store.dispatch('movies/loadConfiguration')
                const movie: MovieView = await store.dispatch(
                    'movies/loadMovieView',
                    route.params.id
                )
                movieView.value = movie
                store.commit('ui/setTitle', movie.title)

                const root = document.documentElement
                root.style.setProperty('--backdrop', `url(${movie.backdrop})`)
                root.style.setProperty(
                    '--poster',
                    `url(${movie.posters[0].poster})`
                )
            })

            return {
                movieView,
            }
        },
    })
