import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "action-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["pi action-button__icon", _ctx.buttonIcon])
    }, null, 2)
  ], 4))
}