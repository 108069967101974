
    import { defineComponent } from 'vue'
    import GraphPanel from '@/components/GraphPanel.vue'

    export default defineComponent({
        name: 'Graph',
        components: {
            GraphPanel
        },
        setup() {
            return {
            }
        },
    })
