import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stat_panel = _resolveComponent("stat-panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_stat_panel, {
      stat: _ctx.movieStats,
      image: "https://images.unsplash.com/photo-1542204165-65bf26472b9b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80"
    }, null, 8, ["stat"]),
    _createVNode(_component_stat_panel, {
      stat: _ctx.musicalStats,
      image: "https://images.unsplash.com/photo-1562329265-95a6d7a83440?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80"
    }, null, 8, ["stat"])
  ], 64))
}