import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content grid" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu = _resolveComponent("main-menu")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_settings = _resolveComponent("settings")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_action_button = _resolveComponent("action-button")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Sidebar, {
      visible: _ctx.menuIsOpen,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuIsOpen) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_main_menu, { onNavigate: _ctx.closeMenu }, null, 8, ["onNavigate"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Sidebar, {
      visible: _ctx.settingsAreOpen,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settingsAreOpen) = $event)),
      position: "full"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_settings)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_nav_bar, { onMenuOpen: _ctx.openMenu }, null, 8, ["onMenuOpen"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ], 512), [
      [_vShow, _ctx.showContent]
    ]),
    (_ctx.showAddButton)
      ? (_openBlock(), _createBlock(_component_action_button, {
          key: 0,
          position: _ctx.addButtonPosition,
          icon: "pi-plus",
          onClicked: _ctx.openAddPanel
        }, null, 8, ["position", "onClicked"]))
      : _createCommentVNode("", true),
    _createVNode(_component_action_button, {
      position: _ctx.settingsButtonPosition,
      icon: "pi-cog",
      onClicked: _ctx.openSettingsPanel
    }, null, 8, ["position", "onClicked"]),
    _createElementVNode("div", _hoisted_2, "Created by Rob Davis | " + _toDisplayString(_ctx.sha) + " | " + _toDisplayString(_ctx.backgroundLocation), 1),
    _createVNode(_component_Toast, { position: "top-right" })
  ], 64))
}