
    import { computed, defineComponent, onBeforeUpdate, onMounted, ref, watch } from 'vue'
    import NavBar from '@/components/NavBar.vue'
    import Sidebar from 'primevue/sidebar'
    import MainMenu from '@/components/MainMenu.vue'
    import Toast from 'primevue/toast'
    import { ActionButtonPosition } from '@/config/index'
    import ActionButton from '@/components/ActionButton.vue'
    import Settings from '@/components/Settings.vue'
    import { MutationPayload, useStore } from 'vuex'
    import { storeKey } from '@/store'
    import { useRoute } from 'vue-router'
    
    export default defineComponent({
        name: 'App',
        components: {
            NavBar,
            Sidebar,
            MainMenu,
            Toast,
            ActionButton,
            Settings
        },
        setup() {
            const menuIsOpen = ref(false)
            const store = useStore(storeKey)
            const showAddButton = ref(true)
            const settingsAreOpen = ref(false)

            const showContent = computed(() => store.state.ui.showContent)

            onBeforeUpdate(() => {
                const route = useRoute()
                showAddButton.value = route.meta?.hasAdd as boolean
            })

            const openAddPanel = () => {
                store.commit('ui/setAddPanelOpen', true)
            }

            const openSettingsPanel = () => {
                store.commit('ui/setSettingsPanelOpen', true)
            }

            const openMenu = () => {
                menuIsOpen.value = true
            }

            const closeMenu = () => {
                menuIsOpen.value = false
            }

            const setBackgroundUrl = (url: string) => {
                const root = document.documentElement
                root.style.setProperty('--backdrop', `url(${url})`)               
                root.style.setProperty('--poster', `url(${url})`)
            }

            watch(() => store.state.ui.background, (url) => setBackgroundUrl(url))

            watch(() => store.state.ui.settingsPanelOpen, (current) => {
                settingsAreOpen.value = current
            })

            const backgroundLocation = computed(() => store.state.ui.backgroundLocation)

            // This can't be async because the entire component would need to be inside a suspense and it's the top level
            onMounted(() => {
                store.dispatch('auth/authSubscribe')
            })

            // watch for the profile to be updated so we can load the background
            store.subscribe((mutation: MutationPayload) => {
                if (mutation.type === 'auth/setProfile') {
                    store.dispatch('ui/loadBackground', mutation.payload.backgroundQuery).then(() => {
                        setBackgroundUrl(store.state.ui.background)
                    })
                }
            })

            return {
                menuIsOpen,
                openMenu,
                closeMenu,
                openAddPanel,
                sha: process.env?.VUE_APP_COMMIT || 'git-hash',
                showAddButton,
                openSettingsPanel,
                addButtonPosition: ActionButtonPosition.right,
                settingsButtonPosition: ActionButtonPosition.left,
                settingsAreOpen,
                backgroundLocation,
                showContent
            }
        },
    })
