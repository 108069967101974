import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_add_movie = _resolveComponent("add-movie")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_Sidebar, {
      visible: _ctx.addIsOpen,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addIsOpen) = $event)),
      position: "full",
      onHide: _ctx.setPanelClosedInStore
    }, {
      default: _withCtx(() => [
        _createVNode(_component_add_movie, { onSaved: _ctx.closeAddPanel }, null, 8, ["onSaved"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ], 64))
}