
    import { computed, defineComponent, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    import StatPanel from '@/components/StatPanel.vue'

    export default defineComponent({
        name: 'Stats',
        components: {
            StatPanel,
        },
        setup() {
            const store = useStore(storeKey)
            const movieStats = computed(() => store.getters['movies/getStats'])
            const musicalStats = computed(() => store.getters['musicals/getStats'])

            onMounted(() => {
                store.dispatch('movies/loadItems')
                store.dispatch('musicals/loadItems')
            })


            return {
                movieStats,
                musicalStats
            }
        },
    })
