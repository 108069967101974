
    import { defineComponent } from 'vue'
    import { ActionButtonPosition } from '@/config/index'

    export default defineComponent({
        name: 'ActionButton',
        components: {},
        emits: ['clicked'],
        props: {
            icon: {
                type: String,
                required: true
            },
            position: {
                type: Number,
                required: true
            }
        },
        setup(props, { emit }) {
            
            const open = () => {
                emit('clicked')
            }
            
            // What was I thinking when I made this?
            const style: Record<string, string | number> = {}
            style[`${ActionButtonPosition[props.position]}`] = 0
            style[`border-bottom-${ActionButtonPosition[Number(!props.position)]}-radius`] = '0.2em'
            style[`border-top-${ActionButtonPosition[Number(!props.position)]}-radius`] = '0.2em'
        
            return {
                open,
                buttonIcon: props.icon,
                style
            }
        },
    })
