
    import { computed, defineComponent } from 'vue'
    import { useRouter } from 'vue-router'
    import Button from 'primevue/button'
    import 'firebase/auth'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    import ToggleButton from 'primevue/togglebutton'
    
    export default defineComponent({
        name: 'NavBar',
        components: {
            Button,
            ToggleButton
        },
        emits: ['menuOpen'],
        setup(props, { emit }) {
            const router = useRouter()
            const store = useStore(storeKey)
            const userLoggedIn = computed(() => store.state.auth.user !== null)
            const title = computed(() => store.state.ui.title)

            const showItems = computed({
                get(): boolean {
                    return store.state.ui.showContent
                },
                set(v: boolean): void {
                    store.commit('ui/setShowContent', v)
                }
            })
            
            const openMenu = () => {
                emit('menuOpen')
            }

            const logout = async () => {
                await store.dispatch('auth/signOut')
                router.push('/')
            }

            return {
                openMenu,
                logout,
                title,
                userLoggedIn,
                showItems
            }
        },
    })
