
    import { defineComponent, provide, onMounted, ref, watch } from 'vue'
    import { useStore } from 'vuex'
    import Sidebar from 'primevue/sidebar'
    import AddMusical from '@/components/AddMusical.vue'
    import { moduleKey, storeKey } from '@/store'
    
    export default defineComponent({
        name: 'Musicals',
        components: {
            Sidebar,
            AddMusical
        },
        setup() {
            const addIsOpen = ref(false)
            provide(moduleKey, 'musicals')
            const store = useStore(storeKey)

            onMounted(() => {
                store.dispatch('musicals/loadItems', store.state.auth.user?.uid)
            })

            watch(() => store.state.ui.addPanelOpen, (current) => {
                addIsOpen.value = current
            })

            const setPanelClosedInStore = () => {
                store.commit('ui/setAddPanelOpen', false)
            }

            const closeAddPanel = () => {
                setPanelClosedInStore()
            }
            
            return {
                addIsOpen,
                setPanelClosedInStore,
                closeAddPanel
            }
        },
    })
