
    import { computed, defineComponent } from 'vue'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    export default defineComponent({
        name: 'MainMenu',
        components: {},
        emits: ['navigate'],
        setup(props, { emit }) {
            const handleClick = () => emit('navigate')

            const store = useStore(storeKey)
            const showFlagged = computed({
                get(): boolean {
                    return store.state.auth.settings.showFlagged
                },

                set (v: boolean) {
                    store.commit('ui/setShowFlagged', v)
                }
            })       

            return {
                handleClick,
                showFlagged
            }
        },
    })
