import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "chart" }, {
    content: _withCtx(() => [
      _createVNode(_component_Chart, {
        class: "fill",
        type: "bar",
        data: _ctx.data,
        options: _ctx.options
      }, null, 8, ["data", "options"])
    ]),
    _: 1
  }))
}