
    import { defineComponent, ref } from 'vue'
    import AutoComplete from 'primevue/autocomplete'
    import AutoCompleteEvent from '@/models/prime-events'
    import MovieSuggestion from '@/models/movie-search'
    import Calendar from 'primevue/calendar'
    import Button from 'primevue/button'
    import ToggleButton from 'primevue/togglebutton'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    import 'firebase/database'
    import ItemData from '@/models/item'
    import Knob from 'primevue/knob'

    export default defineComponent({
        name: 'AddMovie',
        components: {
            AutoComplete,
            Knob,
            Calendar,
            Button,
            ToggleButton
        },
        emits: ['saved'],
        setup(props, { emit }) {
            const suggestions = ref<MovieSuggestion[]>([])
            const selectedMovie = ref<MovieSuggestion>()
            const rating = ref<number>(0)
            const selectedDate = ref<Date>()
            const invalid = ref(false)
            const saving = ref(false)
            const flagged = ref(false)
            const store = useStore(storeKey)
            const itemSelected = ref(false)

            const movieSelected = () => {
                itemSelected.value = true
            }

            const movieCleared = () => {
                itemSelected.value = false
            }

            const save = async () => {
                invalid.value = selectedMovie.value === undefined

                if (invalid.value) {
                    return
                }

                const movie: ItemData = {
                    name: selectedMovie.value?.name || '',
                    posterUrl: selectedMovie.value?.poster?.url || '',
                    posterWidth: selectedMovie.value?.poster?.width || 0,
                    myRating: rating.value || 0,
                    globalRating: selectedMovie.value?.rating || -1,
                    year: selectedDate.value,
                    id: selectedMovie.value?.id || 0,
                    flagged: flagged.value
                }

                saving.value = true
                await store.dispatch('movies/addItem', movie)
                emit('saved')
            }

            const movieSearch = async (event: AutoCompleteEvent) => {
                suggestions.value = await store.dispatch(
                    'movies/loadSuggestions',
                    event.query.trim()
                )
            }

            return {
                movieSearch,
                selectedMovie,
                suggestions,
                rating,
                selectedDate,
                save,
                invalid,
                saving,
                itemSelected,
                movieSelected,
                movieCleared,
                flagged
            }
        },
    })
