
    import { defineComponent, onBeforeUnmount, ref } from 'vue'
    import InputSwitch from 'primevue/inputswitch'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'
    import InputText from 'primevue/inputtext'
        
    export default defineComponent({
        name: 'Settings',
        components: {
            InputSwitch,
            InputText
        },
        setup() {
            const store = useStore(storeKey)
            
            const backgroundQuery = ref<string>(store.state.auth.settings.backgroundQuery)
            const showFlagged = ref<boolean>(store.state.auth.settings.showFlagged)

            onBeforeUnmount(() => {
                // Save the state to the store
                store.commit('ui/setSettingsPanelOpen', false)
                store.dispatch('auth/updateProfile', {
                    backgroundQuery: backgroundQuery.value,
                    showFlagged: showFlagged.value
                })
            })


            return {
                showFlagged,
                backgroundQuery
            }
        },
    })
