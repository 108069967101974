
    import { computed, defineComponent, ref, watch } from 'vue'
    import Card from 'primevue/card'
    import InputText from 'primevue/inputtext'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import { useRouter } from 'vue-router'
    import Notify from '@/util/notify'
    import { useStore } from 'vuex'
    import { storeKey } from '@/store'

    export default defineComponent({
        name: 'Login',
        components: {
            Card,
            InputText,
            Password,
            Button,
        },
        setup() {
            const router = useRouter()
            const password = ref('')
            const email = ref('')
            const loggingIn = ref(false)
            const notify = new Notify()
            const store = useStore(storeKey)
            const ready = computed(() => store.state.auth.ready)

            const login = async () => {
                loggingIn.value = true
                await store.dispatch('auth/signIn', { email: email.value, password: password.value })
                loggingIn.value = false
            }

            watch(() => store.state.auth.user, (user) => {
                if (user) {
                    router.push({ name: 'Movie History'})
                }
            })

            watch(() => store.state.auth.error, (error) => {
                if (error) {
                    notify.show(error)
                }
            })

            return {
                password,
                email,
                loggingIn,
                login,
                ready
            }
        },
    })
