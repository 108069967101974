
    import { defineComponent, PropType } from 'vue'
    import { ItemStats } from '@/store/states/base.state'
    import Card from 'primevue/card'

    export default defineComponent({
        name: 'StatPanel',
        components: {
            Card,
        },
        props: {
            stat: {
                required: true,
                type: Object as PropType<ItemStats>,
            },
            image: {
                required: true,
                type: String
            }
        },
        setup() {
            return {}
        },
    })
