
    import { defineComponent, inject, PropType, ref } from 'vue'
    import ToggleButton from 'primevue/togglebutton'
    import Card from 'primevue/card'
    import Calendar from 'primevue/calendar'
    import ItemData from '@/models/item'
    import Button from 'primevue/button'
    import { useStore } from 'vuex'
    import { moduleKey, storeKey } from '@/store'
    import Knob from 'primevue/knob'
    import ProgressBar from 'primevue/progressbar'
    import { useRouter } from 'vue-router'

    export default defineComponent({
        name: 'Item',
        components: {
            Knob,
            Button,
            Card,
            Calendar,
            ProgressBar,
            ToggleButton
        },
        props: {
            data: {
                type: Object as PropType<ItemData>,
                require: true,
            },

            editKey: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const isEditing = ref(false)
            const flagged = ref(false)
            const selectedDate = ref<Date>(new Date())
            const rating = ref<number>(0)
            const store = useStore(storeKey)
            const module = inject(moduleKey)
            const router = useRouter()

            const view = async () => {
                if (props.data?.id) {
                    let routeData = router.resolve({name: 'Movie', params: { id: props.data?.id }})
                    window.open(routeData.href, '_blank')
                }
            }

            const remove = async () => {
                await store.dispatch(`${module}/removeItem`, props.editKey)
            }

            const edit = async () => {
                if (!isEditing.value) {
                    isEditing.value = true
                } else {
                    // Save
                    const item = { ...props.data }
                    item.myRating = rating.value
                    item.year = selectedDate.value
                    await store.dispatch(`${module}/updateItem`, { item, key: props.editKey })
                    isEditing.value = flagged.value
                }
            }

            const formatDate = (date: Date | string | undefined) => {
                if (!date) {
                    return ''
                } else {
                    return new Date(date).toDateString()
                }
            }

            return {
                isEditing,
                selectedDate,
                rating,
                formatDate,
                edit,
                view,
                remove,
                flagged
            }
        },
    })
