import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80384c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid shadow-3 nav" }
const _hoisted_2 = { class: "col-fixed nav__burger" }
const _hoisted_3 = { class: "col-fixed nav__title" }
const _hoisted_4 = { class: "col nav__user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: "pi pi-bars",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ToggleButton, {
        modelValue: _ctx.showItems,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showItems) = $event)),
        onLabel: "Hide Items",
        class: "nav__item",
        offLabel: "Show Items"
      }, null, 8, ["modelValue"]),
      _withDirectives(_createVNode(_component_Button, {
        label: "Logout",
        onClick: _ctx.logout,
        class: "nav__item"
      }, null, 8, ["onClick"]), [
        [_vShow, _ctx.userLoggedIn]
      ])
    ])
  ]))
}